import {selector} from "recoil";
import axios from 'axios';
import constants from "../../constants";

const randomBooksState = selector({
    key: 'randomBooksState', // unique ID (with respect to other atoms/selectors)
    get: async ({get}) => {
        const result = await axios(constants.API.BASE + constants.API.GET_RANDOM);
        result.data.forEach((book) => {
           book.floatGrade = parseFloat(book.hsk_grade).toFixed(2);
           book.grade = parseInt(parseFloat(book.hsk_grade).toFixed(0));
        });
        return result.data;
    },
});

export default randomBooksState;