import React, {Suspense} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Submit from "../components/submit/Submit";

const SubmitPage = () => {
    return (
        <div>
            <Header />
            <Submit />
            <Footer />
        </div>
    );
};

export default SubmitPage;