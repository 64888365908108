import React from 'react';
import Steps from "./Steps";
import SubmitIsbnForm from "./SubmitIsbnForm";
import {useRecoilState} from "recoil";
import submitStepState from "../../store/atoms/submitStep";
import SubmitConfirmIsbn from "./SubmitConfirmIsbn";
import SubmitReport from "./SubmitReport";

const Submit = () => {
    const [step, setStep] = useRecoilState(submitStepState);

    const steps = [
        {
            id: '1',
            name: 'Enter ISBN',
            href: '#',
            status: step === 0 ? 'current' : 'complete'
        },
        {
            id: '2',
            name: 'Analyse Book',
            href: '#',
            status: step === 1 ? 'current' : step > 1 ? 'complete' : 'upcoming'
        },
        {
            id: '3',
            name: 'HSK Grade Report',
            href: '#',
            status: step === 2 ? 'current' : 'upcoming'
        }
    ];

    return (
        <div className="max-w-7xl m-auto">
            <Steps steps={steps} />
            {step === 0 && <SubmitIsbnForm />}
            {step === 1 && <SubmitConfirmIsbn />}
            {step === 2 && <SubmitReport />}
        </div>
    );
};

export default Submit;