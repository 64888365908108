import {useRecoilState, useRecoilValue} from "recoil";
import submitResult from "../../store/atoms/submitResult";
import submitIsbn from "../../store/atoms/submitIsbn";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import submitIsbnInfo from "../../store/atoms/submitIsbnInfo";

export default function SubmitReport() {
    const report = useRecoilValue(submitResult);
    const isbn = useRecoilValue(submitIsbn);
    const [isbnInfo] = useRecoilState(submitIsbnInfo);

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="relative bg-white shadow-xl">
                    <h2 className="sr-only">Book HSK Level Report</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        {/* Contact information */}
                        <div className="relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12">
                            <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={343}
                                    height={388}
                                    viewBox="0 0 343 388"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                        fill="url(#linear1)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear1"
                                            x1="254.553"
                                            y1="107.554"
                                            x2="961.66"
                                            y2="814.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"/>
                                            <stop offset={1} stopColor="#fff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={359}
                                    height={339}
                                    viewBox="0 0 359 339"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                        fill="url(#linear2)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear2"
                                            x1="192.553"
                                            y1="28.553"
                                            x2="899.66"
                                            y2="735.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"/>
                                            <stop offset={1} stopColor="#fff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={160}
                                    height={678}
                                    viewBox="0 0 160 678"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                        fill="url(#linear3)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear3"
                                            x1="192.553"
                                            y1="325.553"
                                            x2="899.66"
                                            y2="1032.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"/>
                                            <stop offset={1} stopColor="#fff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <h3 className="text-lg font-medium text-white">View Report</h3>
                            <p className="mt-4 text-base text-indigo-50 max-w-3xl">
                                Your book has been analysed! You can now view your report.
                            </p>
                            {/*<h3 className="text-md font-medium text-white mt-8">How does it work?</h3>*/}
                            {/*<p className="mt-4 text-sm text-indigo-50 max-w-3xl">*/}
                            {/*    By submitting the correct ISBN, and submitting your .epub e-book file, we analyses the book's contents and create a detailed HSK-level report.*/}
                            {/*</p>*/}
                            <h3 className="text-md font-medium text-white mt-8">Privacy</h3>
                            <p className="mt-4 text-sm text-indigo-50 max-w-3xl">
                                Your book is never uploaded to our servers. The book is analysed locally on your device, and the HSK grades are sent along with the ISBN to our servers.
                            </p>
                        </div>

                        {/* Contact form */}
                        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                            <h3 className="text-lg font-medium text-gray-900">Book Report</h3>
                            <div className="mt-3">
                                {isbnInfo.title && <p className="text-xl font-bold">{isbnInfo?.title}</p>}
                                <p className="text-lg">ISBN: <em>{isbn}</em></p>
                                {isbnInfo?.image && <p className="my-2">
                                    <img src={isbnInfo?.image} alt={isbnInfo?.title}
                                         className="w-fit h-fit"/>
                                </p>}
                                <p className="text-xl"><strong>HSK Grade:</strong> {report?.results.weightedSum}</p>

                                <h2 className="my-3 text-lg">HSK level of characters in this book</h2>
                                {[{level: 1, color: '#f9b51f'}, {level: 2, color: '#207f97'}, {
                                    level: 3,
                                    color: '#ed6e06'
                                }, {level: 4, color: '#ba1018'}, {level: 5, color: '#1b3e76'}, {
                                    level: 6,
                                    color: '#6a3768'
                                }].map((entry, index) => {
                                    return <div className="inline-flex mr-4" key={'result-'+index}><div className="w-20 h-20 text-center">
                                        <CircularProgressbarWithChildren
                                            value={report?.results.levelsPercent[entry.level]}
                                            styles={buildStyles({
                                                pathColor: entry.color
                                            })}>
                                            <div style={{fontSize: 12, marginTop: -5}}>
                                                <strong>
                                                    HSK {entry.level}<br/>
                                                    {report?.results.levelsPercent[entry.level]}%
                                                </strong>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div></div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
