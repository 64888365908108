export default {
    API: {
        BASE: window.location.href.indexOf('localhost') > -1 ? 'http://localhost:8080' : 'https://api.hsk-books.com',
        GET_RANDOM: '/api/random',
        SUBMIT: '/api/submit?extraIsbn=',
        GET_BOOKS: '/api/books'
    },
    BOOKS_API: {
        BASE: 'https://www.googleapis.com/books/v1/volumes?q=isbn:',
    },
    MIME_TYPES: {
        EPUB: [
            'application/epub+zip'
        ]
    },
    IS_DEV: window.location.href.indexOf('localhost') > -1
};