import React, {Suspense} from 'react';
import BooksList from "../components/BooksList";
import {useRecoilValue} from "recoil";
import randomBooksState from '../store/selectors/randombooks';
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomePage = () => {
    const randomBooks = useRecoilValue(randomBooksState);

    return (
        <div>
            <Header />
            <Suspense fallback={<div>LOADING</div>}>
                <BooksList books={randomBooks}/>
            </Suspense>
            <Footer />
        </div>
    );
};

export default HomePage;