import React from 'react';

const ProgressBar = ({percentage}) => {
    if (percentage > 100) {
        percentage = 100;
    }
    return (
            <div className="w-full bg-gray-100 rounded-full h-2.5 dark:bg-gray-400">
                <div className="bg-green-600 h-2.5 rounded-full" style={{width: percentage+'%'}}></div>
                {percentage != 100 && <p className="text-center">{percentage} %<br /><br />This can take a few minutes for large books.</p>}
                {percentage == 100 && <p className="text-center">Complete, analysing results...</p>}
            </div>
    );
};

export default ProgressBar;