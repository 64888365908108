const isChinese = require('is-chinese-character');
const hskChars = require('hsk-chars');
const hanziRegex = /([\x00-\x7A])/g;


const analyseFullText = async (fullText) => {
    const result = {
        total: 0,
        levels: {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}
    };

    fullText = fullText.replaceAll(hanziRegex, '');
    fullText.split('').forEach((hanzi) => { // try with '' or ' '??
        if (!isChinese.isChineseCharacter(hanzi)) return;
        result.total++;
        const level = hskChars.getMaxHskLevel(hanzi);
        result.levels[level]++;
    });

    return result;
};

export default analyseFullText;