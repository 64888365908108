export default function Example() {
    return (
        <footer className="bg-gray-900 mt-8">
            <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                <div className="mt-8 md:mt-0 md:order-1 m-auto">
                    <p className="text-center text-base text-gray-400">&copy; 2022 HSK-Books, Inc. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
