import {Fragment, useState} from 'react'
import {Dialog, Popover, Tab, Transition} from '@headlessui/react'
import {MenuIcon, QuestionMarkCircleIcon, SearchIcon, ShoppingBagIcon, XIcon} from '@heroicons/react/outline'
import {Link, useLocation} from "react-router-dom";
import {BookmarkAltIcon} from "@heroicons/react/solid";

const currencies = ['CAD', 'USD', 'AUD', 'EUR', 'GBP']
const navigation = {
    categories: [],
    pages: [
        {name: 'Home', href: '/'},
        {name: 'Grade My Book', href: '/submit'},
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const location = useLocation();
    const isOnSubmit = (location.pathname || '').includes('submit');

    return (
        <div className="bg-white mb-8">
            {/* Mobile menu */}
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileMenuOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                                <div className="px-4 pt-5 pb-2 flex">
                                    <button
                                        type="button"
                                        className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>

                                {/* Links */}
                                <Tab.Group as="div" className="mt-2">
                                    <div className="border-b border-gray-200">
                                        <Tab.List className="-mb-px flex px-4 space-x-8">
                                            {navigation.categories.map((category) => (
                                                <Tab
                                                    key={category.name}
                                                    className={({selected}) =>
                                                        classNames(
                                                            selected ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent',
                                                            'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium'
                                                        )
                                                    }
                                                >
                                                    {category.name}
                                                </Tab>
                                            ))}
                                        </Tab.List>
                                    </div>
                                    <Tab.Panels as={Fragment}>
                                        {navigation.categories.map((category) => (
                                            <Tab.Panel key={category.name} className="px-4 py-6 space-y-12">
                                                <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                                                    {category.featured.map((item) => (
                                                        <div key={item.name} className="group relative">
                                                            <div
                                                                className="aspect-w-1 aspect-h-1 rounded-md bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                                <img src={item.imageSrc} alt={item.imageAlt}
                                                                     className="object-center object-cover"/>
                                                            </div>
                                                            <Link to={item.href}
                                                                  className="mt-6 block text-sm font-medium text-gray-900">
                                                                <span className="absolute z-10 inset-0"
                                                                      aria-hidden="true"/>
                                                                {item.name}
                                                            </Link>
                                                            <p aria-hidden="true"
                                                               className="mt-1 text-sm text-gray-500">
                                                                Shop now
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </Tab.Group>

                                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                    {navigation.pages.map((page) => (
                                        <div key={page.name} className="flow-root">
                                            <a href={page.href} className="-m-2 p-2 block font-medium text-gray-900">
                                                {page.name}
                                            </a>
                                        </div>
                                    ))}
                                </div>


                                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Hero section */}
            <div className="relative bg-gray-900">
                {/* Decorative image and overlay */}
                <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
                    {/*https://tailwindui.com/img/ecommerce-images/home-page-01-hero-full-width.jpg*/}
                    <img
                        src={isOnSubmit ? '/img/bg-temple.webp' : "/img/bg-lanterns-2.webp"}
                        alt=""
                        className="w-full h-full object-center object-cover"
                    />
                </div>
                <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50"/>

                {/* Navigation */}
                <header className="relative z-10">
                    <nav aria-label="Top">
                        {/* Secondary navigation */}
                        <div className="backdrop-blur-md backdrop-filter bg-opacity-10 bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div>
                                    <div className="h-16 flex items-center justify-between">
                                        {/* Logo (lg+) */}
                                        <div className="hidden lg:flex-1 lg:flex lg:items-center">
                                            <Link to="/">
                                                <span className="sr-only">HSK Books</span>

                                                <h1 className="text-white text-xl mt-1">
                                                    <BookmarkAltIcon className="text-red-600 w-12 h-12 inline mr-3 mb-1"/>
                                                    HSK Graded Books</h1>
                                            </Link>
                                        </div>

                                        <div className="hidden h-full lg:flex">
                                            {/* Flyout menus */}
                                            <Popover.Group className="px-4 bottom-0 inset-x-0">
                                                <div className="h-full flex justify-center space-x-8">
                                                    {navigation.categories.map((category) => (
                                                        <Popover key={category.name} className="flex">
                                                            {({open}) => (
                                                                <>
                                                                    <div className="relative flex">
                                                                        <Popover.Button
                                                                            className="relative z-10 flex items-center justify-center transition-colors ease-out duration-200 text-sm font-medium text-white">
                                                                            {category.name}
                                                                            <span
                                                                                className={classNames(
                                                                                    open ? 'bg-white' : '',
                                                                                    'absolute -bottom-px inset-x-0 h-0.5 transition ease-out duration-200'
                                                                                )}
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Popover.Button>
                                                                    </div>

                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-200"
                                                                        enterFrom="opacity-0"
                                                                        enterTo="opacity-100"
                                                                        leave="transition ease-in duration-150"
                                                                        leaveFrom="opacity-100"
                                                                        leaveTo="opacity-0"
                                                                    >
                                                                        <Popover.Panel
                                                                            className="absolute top-full inset-x-0 text-sm text-gray-500">
                                                                            {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                                                            <div
                                                                                className="absolute inset-0 top-1/2 bg-white shadow"
                                                                                aria-hidden="true"/>

                                                                            <div className="relative bg-white">
                                                                                <div
                                                                                    className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                                                                    <div
                                                                                        className="grid grid-cols-4 gap-y-10 gap-x-8 py-16">
                                                                                        {category.featured.map((item) => (
                                                                                            <div key={item.name}
                                                                                                 className="group relative">
                                                                                                <div
                                                                                                    className="aspect-w-1 aspect-h-1 rounded-md bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                                                                    <img
                                                                                                        src={item.imageSrc}
                                                                                                        alt={item.imageAlt}
                                                                                                        className="object-center object-cover"
                                                                                                    />
                                                                                                </div>
                                                                                                <a href={item.href}
                                                                                                   className="mt-4 block font-medium text-gray-900">
                                                                                                    <span
                                                                                                        className="absolute z-10 inset-0"
                                                                                                        aria-hidden="true"/>
                                                                                                    {item.name}
                                                                                                </a>
                                                                                                <p aria-hidden="true"
                                                                                                   className="mt-1">
                                                                                                    Shop now
                                                                                                </p>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Popover.Panel>
                                                                    </Transition>
                                                                </>
                                                            )}
                                                        </Popover>
                                                    ))}

                                                    {navigation.pages.map((page) => (
                                                        <a
                                                            key={page.name}
                                                            href={page.href}
                                                            className="flex items-center text-sm font-medium text-white"
                                                        >
                                                            {page.name}
                                                        </a>
                                                    ))}
                                                </div>
                                            </Popover.Group>
                                        </div>

                                        {/* Mobile menu and search (lg-) */}
                                        <div className="flex-1 flex items-center lg:hidden">
                                            <button type="button" className="-ml-2 p-2 text-white"
                                                    onClick={() => setMobileMenuOpen(true)}>
                                                <span className="sr-only">Open menu</span>
                                                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>

                                            {/* Search */}
                                            {/*<a href="#" className="ml-2 p-2 text-white">*/}
                                            {/*    <span className="sr-only">Search</span>*/}
                                            {/*    <SearchIcon className="w-6 h-6" aria-hidden="true" />*/}
                                            {/*</a>*/}
                                        </div>

                                        {/* Logo (lg-) */}
                                        <Link to="/" className="lg:hidden">
                                            <span className="sr-only">HSK Books</span>
                                            {/*<h1 className="text-white text-lg">HSK Graded Books</h1>*/}
                                            <h1 className="text-white text-lg mt-1">
                                                <BookmarkAltIcon className="text-red-600 w-12 h-12 inline mr-3 mb-1"/>
                                                HSK Graded Books</h1>
                                        </Link>

                                        <div className="flex-1 flex items-center justify-end">
                                            {/*<div className="flex items-center lg:ml-8">*/}
                                            {/*    /!* Help *!/*/}
                                            {/*    <a href="#" className="p-2 text-white lg:hidden">*/}
                                            {/*        <span className="sr-only">Help</span>*/}
                                            {/*        <QuestionMarkCircleIcon className="w-6 h-6" aria-hidden="true" />*/}
                                            {/*    </a>*/}
                                            {/*    <a href="#" className="hidden text-sm font-medium text-white lg:block">*/}
                                            {/*        Help*/}
                                            {/*    </a>*/}

                                            {/*    /!* Cart *!/*/}
                                            {/*    <div className="ml-4 flow-root lg:ml-8">*/}
                                            {/*        <a href="#" className="group -m-2 p-2 flex items-center">*/}
                                            {/*            <ShoppingBagIcon className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />*/}
                                            {/*            <span className="ml-2 text-sm font-medium text-white">0</span>*/}
                                            {/*            <span className="sr-only">items in cart, view bag</span>*/}
                                            {/*        </a>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>

                <div
                    className="relative max-w-3xl mx-auto py-16 px-6 flex flex-col items-center text-center sm:py-16 lg:px-0">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl">Graded Chinese
                        Books</h1>
                    <p className="mt-4 text-xl text-white">
                        Accurately graded Chinese books, ideal to choose your next Chinese book in your learning
                        adventure.
                    </p>
                    <Link
                        to="/submit"
                        className="mt-8 inline-block bg-white border border-transparent rounded-md py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100"
                    >
                        Grade My E-Book
                    </Link>
                </div>
            </div>

        </div>
    )
}
