import {StarIcon} from '@heroicons/react/solid'
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {PieChart} from 'react-minimal-pie-chart';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BooksList({books}) {
    const percentages = {};
    books.forEach((book) => {
        percentages[book.id] = [];
        [{level: 1, color: '#f9b51f'}, {level: 2, color: '#207f97'}, {
            level: 3,
            color: '#ed6e06'
        }, {level: 4, color: '#ba1018'}, {level: 5, color: '#1b3e76'}, {
            level: 6,
            color: '#6a3768'
        }].forEach((entry) => {
            const percent = (book['hsk' + entry.level] / book?.total_chars) * 100;
            percentages[book.id].push({title: entry.level, value: percent, color: entry.color});
        });
    });
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8">
                <h2 className="sr-only">Products</h2>

                <div className="-mx-px border-l border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-5">
                    {books.map((book) => (
                        <div key={book.id} className="group relative p-4 border-r border-b border-gray-200 sm:p-6">
                            <div
                                className="rounded-lg overflow-hidden bg-gray-100 aspect-w-1 aspect-h-1 group-hover:opacity-75 text-center">
                                <img
                                    src={book.cover_image || 'https://images.pexels.com/photos/2663851/pexels-photo-2663851.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                                    alt={book.title}
                                    className={"w-fit h-fit object-contain m-auto"}
                                />
                            </div>
                            <div className="pt-10 text-center">
                                <h3 className="text-sm font-medium text-gray-900">
                                        <span aria-hidden="true" className="absolute inset-0"/>
                                        {book.title}
                                </h3>
                                <h4 className="text-xs mt-2">
                                    {book.author}
                                </h4>
                                <div className="mt-3 flex flex-col items-center">
                                    <p className="sr-only">{book.grade} out of 6 HSK levels</p>
                                    <div className="flex items-center">
                                        {[0, 1, 2, 3, 4, 5].map((rating, index) => (
                                            <StarIcon
                                                key={rating}
                                                className={classNames(
                                                    book.grade === 1 ? (book.grade > rating ? 'text-yellow-400' : 'text-gray-200') :
                                                        book.grade === 2 ? (book.grade > rating ? 'text-green-600' : 'text-gray-200') :
                                                            book.grade === 3 ? (book.hsk_grade > rating ? 'text-orange-500' : 'text-gray-200') :
                                                                book.grade === 4 ? (book.grade > rating ? 'text-red-600' : 'text-gray-200') :
                                                                    book.grade === 5 ? (book.grade > rating ? 'text-blue-700' : 'text-gray-200') :
                                                                        book.grade === 6 ? (book.grade > rating ? 'text-purple-700' : 'text-gray-200') :
                                                                            '',
                                                    'flex-shrink-0 h-5 w-5'
                                                )}
                                                aria-hidden="true"
                                            />
                                        ))}
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500">Graded HSK {book.floatGrade}</p>
                                    <div className="w-32 h-32 mt-3">
                                        <PieChart data={percentages[book.id]}
                                                  label={({dataEntry}) => dataEntry.title}
                                                  labelStyle={{
                                                      // fill: "white",
                                                      fontSize: '8px',
                                                  }}
                                                  lineWidth={30}
                                                  />
                                    </div>
                                    {/*{[{level: 1, color: '#f9b51f'}, {level: 2, color: '#207f97'}, {*/}
                                    {/*    level: 3,*/}
                                    {/*    color: '#ed6e06'*/}
                                    {/*}, {level: 4, color: '#ba1018'}, {level: 5, color: '#1b3e76'}, {*/}
                                    {/*    level: 6,*/}
                                    {/*    color: '#6a3768'*/}
                                    {/*}].map((entry, index) => {*/}
                                    {/*    const percent = (book['hsk' + entry.level] / book?.total_chars) * 100;*/}
                                    {/*    console.log(percent);*/}
                                    {/*    return <div className="inline-flex mr-4" key={'result-' + index}>*/}
                                    {/*        <div className="w-6 h-6 text-center">*/}
                                    {/*            <CircularProgressbarWithChildren*/}
                                    {/*                value={percent}*/}
                                    {/*                styles={buildStyles({*/}
                                    {/*                    pathColor: entry.color*/}
                                    {/*                })}>*/}
                                    {/*                <div style={{fontSize: 12, marginTop: -5}}>*/}
                                    {/*                    /!*<strong>*!/*/}
                                    {/*                    /!*    HSK {entry.level}<br/>*!/*/}
                                    {/*                    /!*    {percent}%*!/*/}
                                    {/*                    /!*</strong>*!/*/}
                                    {/*                </div>*/}
                                    {/*            </CircularProgressbarWithChildren>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*})}*/}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
