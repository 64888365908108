import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import HomePage from "./pages/Home.page";
import {RecoilRoot} from "recoil";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SubmitPage from "./pages/Submit.page";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/submit" element={<SubmitPage />} />
                    <Route path="/*" element={<HomePage />} />
                </Routes>
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
