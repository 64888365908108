import {useRecoilState, useSetRecoilState} from "recoil";
import submitIsbnState from "../../store/atoms/submitIsbn";
import constants from "../../constants";
import submitStepState from "../../store/atoms/submitStep";
import submitIsbnInfo from "../../store/atoms/submitIsbnInfo";
import {useEffect, useRef, useState} from "react";
import InputFile from "../Forms/InputFile";
import submitResult from "../../store/atoms/submitResult";
import {ReactReader} from "react-reader";
import ProgressBar from "../ProgressBar";
import analyseFullText from "../../utils/analyse";
import axios from "axios";

const MAX_CHAPTERS = 300;

export default function SubmitConfirmIsbn() {
    const [isbn] = useRecoilState(submitIsbnState);
    const [isbnInfo] = useRecoilState(submitIsbnInfo);
    const setStep = useSetRecoilState(submitStepState);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [file, setFile] = useState(null);
    const [hasError, setHasError] = useState(false);
    const setSubmitResult = useSetRecoilState(submitResult);
    const epubReaderRef = useRef();
    const renditionRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);

    // And your own state logic to persist state
    const [location, setLocation] = useState(null)
    const locationChanged = epubcifi => {
        // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
        setLocation(epubcifi)
    }

    const changeIsbn = () => {
        setStep(0);
    };

    const confirmIsbn = () => {
        setIsConfirmed(true);
    };

    const onFileCallback = (file) => {
        setFile(file);
    };

    const submitBook = async () => {
        setIsProcessing(true);
        try {
            setHasError(false);

            constants.IS_DEV && console.log('Epub rendition', renditionRef);

            let previousPageContent = '';
            let currentPageContent = '';
            let totalChaptersFound = 0;
            const totalChaptersIndicated = renditionRef.current.book?.resources?.html?.length || MAX_CHAPTERS;
            console.log(totalChaptersIndicated);
            let fullText = '';
            for (let i = 0; i < totalChaptersIndicated + 10; i++) {
                await renditionRef.current.next();
                // Get the text content
                previousPageContent = currentPageContent;
                currentPageContent = renditionRef.current.getContents()[0].content.textContent;

                if (currentPageContent !== previousPageContent) {
                    fullText += currentPageContent;
                    totalChaptersFound++;
                }

                setProgress(Math.ceil(i * 100 / totalChaptersIndicated));
            }
            console.log('Done processing, total chapters: ', totalChaptersFound);
            const result = await analyseFullText(fullText);
            constants.IS_DEV && console.log('Results from analysis', result);

            const resultApi = await axios.post(constants.API.BASE + constants.API.SUBMIT + isbn, {...result, isbn});
            setSubmitResult(resultApi.data);
            setStep(2);
        } catch (error) {
            console.error('Error submitting book for processing', error);
            setSubmitResult(false);
            setHasError(true);
        }
    };

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className={"relative bg-white shadow-xl"}>
                    <h2 className="sr-only">Enter book ISBN</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        {/* Contact information */}
                        <div className="relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12">
                            <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={343}
                                    height={388}
                                    viewBox="0 0 343 388"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                        fill="url(#linear1)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear1"
                                            x1="254.553"
                                            y1="107.554"
                                            x2="961.66"
                                            y2="814.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"/>
                                            <stop offset={1} stopColor="#fff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={359}
                                    height={339}
                                    viewBox="0 0 359 339"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                        fill="url(#linear2)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear2"
                                            x1="192.553"
                                            y1="28.553"
                                            x2="899.66"
                                            y2="735.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"/>
                                            <stop offset={1} stopColor="#fff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={160}
                                    height={678}
                                    viewBox="0 0 160 678"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                        fill="url(#linear3)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear3"
                                            x1="192.553"
                                            y1="325.553"
                                            x2="899.66"
                                            y2="1032.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"/>
                                            <stop offset={1} stopColor="#fff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            {!isConfirmed && <><h3 className="text-lg font-medium text-white">Confirm Book</h3>
                                <p className="mt-4 text-base text-indigo-50 max-w-3xl">
                                    Before we proceed, please confirm this is the correct book.
                                </p></>}
                            {isConfirmed && <><h3 className="text-lg font-medium text-white">Submit Book</h3>
                                <p className="mt-4 text-base text-indigo-50 max-w-3xl">
                                    Submit your book's .epub file, so we can analyse its HSK level.
                                    <br/><br/>The file is deleted immediately after processing and we do not use it for
                                    any other purpose.
                                </p></>}

                            <h3 className="text-md font-medium text-white mt-8">How does it work?</h3>
                            <p className="mt-4 text-sm text-indigo-50 max-w-3xl">
                                By submitting the correct ISBN, and submitting your .epub e-book file, we analyses the
                                book's contents and create a detailed HSK-level report.
                            </p>
                            <h3 className="text-md font-medium text-white mt-8">Privacy</h3>
                            <p className="mt-4 text-sm text-indigo-50 max-w-3xl">
                                Your book is never uploaded to our servers. The book is analysed locally on your device, and the HSK grades are sent along with the ISBN to our servers.
                            </p>
                        </div>

                        {/* Contact form */}
                        {!isConfirmed && <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                            <h3 className="text-lg font-medium text-gray-900">Confirm Book</h3>
                            <p className="mt-3">Please confirm this is the correct book.</p>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                <div>
                                    <p>ISBN: <em>{isbn}</em></p>
                                    <p>Title: <em>{isbnInfo.title}</em></p>
                                    <p>Publish date: <em>{isbnInfo.publishDate}</em></p>
                                    {isbnInfo.image && <p className="my-2">
                                        <img src={isbnInfo.image} alt={isbnInfo.title} className="w-full h-full"/>
                                    </p>}
                                </div>

                                <div className="sm:col-span-2 sm:flex sm:justify-start">
                                    <button
                                        className="mt-2 mr-4 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                        onClick={changeIsbn}
                                    >
                                        Change ISBN
                                    </button>
                                    <button
                                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                        onClick={confirmIsbn}
                                    >
                                        This is the book, continue
                                    </button>
                                </div>
                            </div>
                        </div>}

                        {isConfirmed && <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                            <h3 className="text-lg font-medium text-gray-900">Submit .epub File For Analysis</h3>
                            <p className="mt-3">The ebook file will not be used for any other purpose than analysing the
                                HSK level. We do not keep any submitted files.</p>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                <div>
                                    <p>ISBN: <em>{isbn}</em></p>
                                    <p>Title: <em>{isbnInfo.title}</em></p>
                                    <p>Publish date: <em>{isbnInfo.publishDate}</em></p>

                                    <div className="mt-6">
                                        <InputFile allowedMimeTypes={constants.MIME_TYPES.EPUB}
                                                   onSuccessCallback={onFileCallback}/>
                                    </div>
                                </div>

                                {hasError && <div className="sm:col-span-2 max-w-lg text-red-500">
                                    <p>
                                        An error occurred during processing. This book might not be supported. You can try again by refreshing this page.
                                    </p>
                                </div>}

                                <div className="sm:col-span-2 sm:flex sm:justify-start">
                                    {!isProcessing && <button
                                        className={"mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto" + (file ? ' bg-indigo-600 ' : ' bg-gray-300 hover:bg-gray-300 ')}
                                        onClick={submitBook}
                                        disabled={file ? false : 'disabled'}
                                    >
                                        Start analysis
                                    </button>}
                                {isProcessing && !hasError && <ProgressBar percentage={progress}/>}
                                </div>

                            </div>
                        </div>}
                        {file && <div className="container" style={{visible: "false"}}>
                            {/*<ReactReader url={file} />*/}
                            <ReactReader
                                location={location}
                                locationChanged={locationChanged}
                                url={file}
                                ref={epubReaderRef}
                                getRendition={rendition => (renditionRef.current = rendition)}
                                showToc={false}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}