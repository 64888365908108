import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import submitIsbnState from "../../store/atoms/submitIsbn";
import axios from "axios";
import constants from "../../constants";
import _get from "lodash/get";
import submitStepState from "../../store/atoms/submitStep";
import submitIsbnInfo from "../../store/atoms/submitIsbnInfo";
import {useEffect, useState} from "react";

export default function SubmitIsbnForm() {
    const [isbn, setIsbn] = useRecoilState(submitIsbnState);
    const [isbnInfo, setIsbnInfo] = useRecoilState(submitIsbnInfo);
    const setStep = useSetRecoilState(submitStepState);
    const [hasError, setHasError] = useState(false);

    const submitIsbn = async () => {
        try {
            setHasError(false);
            const response = await axios(constants.BOOKS_API.BASE + parseInt(isbn));
            const data = _get(response , 'data.items[0].volumeInfo', {});
            if (!data?.title) { throw new Error('Missing title'); }
            setIsbnInfo({
                title: data?.title,
                author: (data?.authors || []).join(' '),
                publishDate: data?.publishedDate,
                description: data?.description,
                image: data?.imageLinks?.thumbnail || data?.imageLinks?.smallThumbnail
            });
            setStep(1);
        } catch (error) {
            console.error('Could not fetch book with ISBN', isbn);
            setIsbnInfo(false);
            setHasError(true);
        }
    };

    useEffect(() => {
        if (constants.IS_DEV) {
            setIsbn('9789866116421');
            submitIsbn();
        }
    }, []);

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="relative bg-white shadow-xl">
                    <h2 className="sr-only">Enter book ISBN</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        {/* Contact information */}
                        <div className="relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12">
                            <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={343}
                                    height={388}
                                    viewBox="0 0 343 388"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                        fill="url(#linear1)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear1"
                                            x1="254.553"
                                            y1="107.554"
                                            x2="961.66"
                                            y2="814.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff" />
                                            <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={359}
                                    height={339}
                                    viewBox="0 0 359 339"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                        fill="url(#linear2)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear2"
                                            x1="192.553"
                                            y1="28.553"
                                            x2="899.66"
                                            y2="735.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff" />
                                            <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={160}
                                    height={678}
                                    viewBox="0 0 160 678"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                        fill="url(#linear3)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear3"
                                            x1="192.553"
                                            y1="325.553"
                                            x2="899.66"
                                            y2="1032.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff" />
                                            <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <h3 className="text-lg font-medium text-white">Analyse Book</h3>
                            <p className="mt-4 text-base text-indigo-50 max-w-3xl">
                                To analyse a Chinese book, please enter its ISBN number first.
                            </p>
                            <h3 className="text-md font-medium text-white mt-8">How does it work?</h3>
                            <p className="mt-4 text-sm text-indigo-50 max-w-3xl">
                                By submitting the correct ISBN, and submitting your .epub e-book file, we analyses the book's contents and create a detailed HSK-level report.
                            </p>
                            <h3 className="text-md font-medium text-white mt-8">Privacy</h3>
                            <p className="mt-4 text-sm text-indigo-50 max-w-3xl">
                                Your book is never uploaded to our servers. The book is analysed locally on your device, and the HSK grades are sent along with the ISBN to our servers.
                            </p>
                        </div>

                        {/* Contact form */}
                        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                            <h3 className="text-lg font-medium text-gray-900">Enter Book ISBN</h3>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                <div>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                                        Book ISBN (10 or 13 number code, only enter numbers)
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="number"
                                            name="submit-isbn"
                                            id="submit-isbn"
                                            autoComplete="isbn"
                                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            value={isbn}
                                            onChange={(e) => { setIsbn(e.target.value); e.preventDefault(); }}
                                        />
                                    </div>
                                </div>

                                {hasError && <div className="sm:col-span-2 max-w-lg text-red-500">
                                    <p>
                                        Unfortunately we couldn't find a book with that ISBN. We only accept ISBN numbers that exist in Google Books.
                                    </p>
                                    <p className="mt-4">Please try again with another ISBN number.</p>
                                </div>}

                                <div className="sm:col-span-2 sm:flex sm:justify-start">
                                    <button
                                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                        onClick={submitIsbn}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
