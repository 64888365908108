import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import filesize from 'filesize';
import _get from 'lodash/get';
import _identity from 'lodash/identity';
import {DocumentAddIcon, DocumentDownloadIcon, PaperClipIcon} from '@heroicons/react/outline';

function InputFile({allowedMimeTypes = [], description, onSuccessCallback = _identity}) {
	const [file, setFile] = useState(null);
	const [showFileTypeError, setShowFileTypeError] = useState(false);
	const onDrop = useCallback(
		(acceptedFiles) => {
			setShowFileTypeError(false);
			const thisFile = acceptedFiles[0];
			if (!thisFile || !allowedMimeTypes.includes(thisFile.type)) {
				setShowFileTypeError(true);
				return false;
			}
			setFile(thisFile);
			thisFile && onSuccessCallback && onSuccessCallback(thisFile);
		},
		[allowedMimeTypes, onSuccessCallback]
	);
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
	const fileSize = filesize(_get(file, 'size', 0));

	return (
		<div className="max-w-lg" {...getRootProps()}>
			<div
				className={`flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ${
					isDragActive ? 'border-green-600' : ''
				}`}>
				<div className="space-y-1 text-center">
					{file ? (
						<PaperClipIcon className="mx-auto h-12 w-12 text-gray-400" />
					) : isDragActive ? (
						<DocumentDownloadIcon className="mx-auto h-12 w-12 text-gray-400" />
					) : (
						<DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400" />
					)}
					<div className="text-sm text-gray-600">
						{file ? (
							<div className="text-black">
								<p className="mb-2">{file.name}</p>
								<p>{fileSize}</p>
							</div>
						) : null}{' '}
						{!file ? (
							isDragActive ? (
								<p>Drop your file here</p>
							) : (
								<p>Press to upload a file or drag and drop</p>
							)
						) : null}
						<input
							id="file-upload"
							name="file-upload"
							type="file"
							className="sr-only"
							{...getInputProps()}
						/>
						{showFileTypeError && (
							<p className="text-yellow-600 font-bold mt-2">
								The selected file's type is not supported, please try again.
							</p>
						)}
					</div>
					<p className="text-xs text-gray-500">{description}</p>
				</div>
			</div>
		</div>
	);
}

export default InputFile;
